import jQuery from "jquery";

const $ = jQuery;

export default function() {
  $(function() {
    $("#layout-header-mobile-navbar-container").hide();

    $("#layout-mobile-menu-link").on("click", function() {
      $("#layout-header-mobile-navbar-container").toggle();
    });

    $(document).on("click", function(event) {
      var container = $("#layout-header-mobile-navbar-container");
      var menuButton = $("#layout-mobile-menu-link");
      if (!container.is(event.target) && !container.has(event.target).length && 
          !menuButton.is(event.target) && !menuButton.has(event.target).length) {
        container.hide();
      }
    });
  });
}
