import jQuery from "jquery";

const $ = jQuery;

export default function() {
  $(function() {
    $("#layout-header-categories-nav-container").hide();

    $("#layout-nav-categories-link").on("mouseenter", function() {
      $("#layout-header-categories-nav-container").show();
    });

    $("#layout-nav-categories-link, #layout-header-categories-nav-container").on("mouseleave", function() {
      setTimeout(function () {
        if (!$("#layout-header-categories-nav-container").is(":hover") && 
            !$("#layout-nav-categories-link").is(":hover")) {
          $("#layout-header-categories-nav-container").hide();
        }
      }, 100);
    });
  });
}
