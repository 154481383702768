import jQuery from "jquery";

const $ = jQuery;

function clipDescriptionParagraph() {
  $(".podcast-description-box_container").each(function() {
    const description = $(this).children(".podcast-description-box_description").first();
    const metadataHeight = $(this).children(".podcast-description-box_metadata").first().height();
    const headingHeight = $(description).children(".podcast-description-box_title").first().height();
    const clippedParagraph = $(description).children("p").first();

    const numOfLines = Math.floor((120 - metadataHeight - headingHeight) / 23);
    $(clippedParagraph).css("webkitLineClamp", `${numOfLines}`);
  });
}

export default function() {
  $(function() {
    clipDescriptionParagraph();

    $(window).on("resize", function() {
      clipDescriptionParagraph();
    });
  });
}
