import jQuery from "jquery"

import SurgeonTalkParameterWatcher from "../surgeon-talk-parameter-watcher";

const $ = jQuery;
const defined = {};
const PARAMETER_NAME_SORT = 'sortierung'
const PARAMETER_NAME_FILTER = 'filter'

export default function(sortSelector, sortDefaultValue, filterSelector=null, filterDefaultValue=null) {
  if (!defined[sortSelector]) {
    defined[sortSelector] = true;

    $(function() {
      $(sortSelector).val(sortDefaultValue);
      $(filterSelector).val(filterDefaultValue);
    });

    jQuery(document).on('turbo:load', () => {
      // filterSelector is set if and only if it isn't the categoryView
      const watcher = new SurgeonTalkParameterWatcher(PARAMETER_NAME_SORT, !filterSelector);
      const currentParameterValue = watcher.getCurrentValue();
      const select = jQuery(sortSelector);
      select.val(currentParameterValue || sortDefaultValue);
      select.on('change', () => {
        watcher.updateCurrentValue(select.val());
      });
    });
  }

  // filterSelector only exists in the archive
  if (filterSelector && !defined[filterSelector]) {
    defined[filterSelector] = true;

    jQuery(document).on('turbo:load', () => {
      const watcher = new SurgeonTalkParameterWatcher(PARAMETER_NAME_FILTER);
      const currentParameterValue = watcher.getCurrentValue();
      const select = jQuery(filterSelector);
      select.val(currentParameterValue || filterDefaultValue);
      select.on('change', () => {
        watcher.updateCurrentValue(select.val());
      });
    });
  }
}
