import jQuery from "jquery";

const $ = jQuery;

export default function(boxClassName, popupClassName) {
  const getNumericID = elementID => {
    const splitArr = elementID.split("__");
    return splitArr[splitArr.length - 1];
  }

  $(function() {
    $(`.${boxClassName}`).on("click", function() {
      $(`#${popupClassName}__${getNumericID(this.id)}`).show();
      $(`#${popupClassName}-container`).show();
    });

    $(`.${popupClassName}-close-button`).on("click", function() {
      $(`#${popupClassName}-container`).hide();
      $(this).parent().hide();
    })
  });
}
