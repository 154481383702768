import jQuery from "jquery";
import Plyr from "plyr";

export default function (selector) {
  jQuery(document).one("turbo:load", () => {
    // excute only if element for player (specified by selector) exists on the page and player has not been initalized
    if (jQuery(selector).length && !jQuery(selector).attr("data-initialized-plyr")) {
      const player = new Plyr(selector, {
        controls: ["play-large", "play", "progress"],
      });

      jQuery(selector).attr("data-initialized-plyr", 1);
      jQuery(document).one("turbo:before-render", () => player.destroy() );
    }
  });
}
