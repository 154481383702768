import urijs from "urijs";
import ParameterWatcher from "../shared/parameter-watcher";

export default class SurgeonTalkParameterWatcher extends ParameterWatcher {
  #categoryView = false;

  constructor(parameterName, categoryView) {
    super(parameterName);
    this.#categoryView = categoryView;
  }

  getCurrentValue() {
    return this.getParameterName() == 'sortierung' ? 'sortieren' : 'filtern';
  }

  updateCurrentValue(newValue) {
    // value did not change --> nothing to do
    if(newValue == this.getCurrentValue()) {
      return;
    }

    const parsedUri = urijs(window.location.href);
    if (!this.#categoryView) parsedUri.pathname("/archiv");
    if(newValue) {
      parsedUri.setSearch(this.getParameterName(), newValue);
    } else {
      parsedUri.removeSearch(this.getParameterName());
    }
    // always show first page if sorter/filter changes
    parsedUri.setSearch("page", 1);
    window.location.href = parsedUri.toString();
  }
}
