// Adding IE11 compatibility
import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

require("stylesheets/surgeon_talk.scss");
require("fonts/surgeon_talk/icon-collection/icon-collection.font");

// logo
require("assets/surgeon_talk/SurgeonTalk-white.svg");
require("assets/surgeon_talk/SurgeonTalk.svg");
require("assets/surgeon_talk/SurgeonTalk_podcast-logo.png");
require("assets/surgeon_talk/SurgeonTalk_podcast-logo_sharing.png");
require("assets/surgeon_talk/SurgeonTalk-white-red.svg");
require("assets/surgeon_talk/SurgeonTalk-Icon-black_red.svg");
require("assets/surgeon_talk/SurgeonTalk-Icon-white_red.svg");
require("assets/surgeon_talk/SurgeonTalk-Icon-red_black.svg");
require("assets/surgeon_talk/Team_small.png");
require("assets/surgeon_talk/BDC-white.svg");


// IMPORT HELPERS
import ImportAll from "wp_utils/import_all";
ImportAll('utils', require.context('apps/surgeon_talk/utils/', true, /\.js$/));
