import jQuery from "jquery";

const $ = jQuery;

export default function() {
  $(function() {
    $("#layout-header-search-field-container").hide();

    $("#layout-nav-search-link").on("click", function() {
      $("#layout-header-search-field-container").toggle();
    });

    $(document).on("click", function(event) {
      var container = $("#layout-header-search-field-container");
      var searchButton = $("#layout-nav-search-link");
      if (!container.is(event.target) && !container.has(event.target).length && 
          !searchButton.is(event.target) && !searchButton.has(event.target).length) {
        container.hide();
      }
    });
  });
}
